import lt from 'vuetify/lib/locale/lt'

export default {
  back: 'atgal',
  client: 'klientas',
  choiceOneVariant: 'pasirinkti vieną variantą',
  comment: 'Komentaras',
  copySendManager: 'kopija bus išsiųsta vadovui el. paštu',
  enterClientCode: 'įveskite kliento kodą',
  enterYourName: 'įveskite savo vardą',
  enterYourPhone: 'įveskite savo telefono numerį',
  enterYourVariant: 'įveskite savo variantą',
  equipment: 'įranga',
  errorLoadingFromServer: 'klaida kraunant iš serverio',
  fault: 'Gedimas',
  faultCodeIfAny: 'gedimo kodas, jei yra',
  faultGroup: 'Gedimų grupė',
  group: 'Grupė',
  newOrder: 'Naujas užsakymas',
  logInPlease: 'prisijunkite, prašome',
  location: 'lokacija',
  next: 'toliau',
  noAccess: 'Nėra prieigos! Patikrinkite įvestų duomenų teisingumą.',
  other: 'kita',
  orderCreated: 'Užsakymas sukurtas',
  orderNumber: 'Užsakymo numeris',
  reason: 'Priežastis',
  retry: 'bandyti dar kartą',
  serviceStopped:
    'Paslauga sustabdyta, dėl informacijos kreipkitės į savo vadovą.',
  success: 'sėkmė',
  wantAddSomething: 'norite ką nors pridėti?',
  validation: {
    phone: 'turi būti galiojantis telefono numeris: +XXXXXXXXXX',
    required: 'negali būti tuščias'
  },
  $vuetify: lt
}
